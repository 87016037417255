import { Suspense, lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AppLayout from "@/components/Layout";
import { Spinner } from "@material-tailwind/react";

const FallBack: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner className="h-20 w-20 text-gray-900/50" />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

const Home = lazy(() => import("@/pages/home/index"));

const Routes = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: (
          <FallBack>
            <Home />
          </FallBack>
        ),
      },
      {
        path: "/topic/:id",
        lazy: async () => ({
          Component: (await import("@/pages/topic/index")).default,
        }),
      },
      {
        path: "/topik",
        lazy: async () => ({
          Component: (await import("@/pages/topik/index")).default,
        }),
      },
      {
        path: "/category/:id",
        lazy: async () => ({
          Component: (await import("@/pages/category/index")).default,
        }),
      },
      {
        path: "/category",
        lazy: async () => ({
          Component: (await import("@/pages/category/list")).default,
        }),
      },
      {
        path: "/word",
        lazy: async () => ({
          Component: (await import("@/pages/word/index")).default,
        }),
      },
      {
        path: "/about",
        lazy: async () => ({
          Component: (await import("@/pages/about/index")).default,
        }),
      },
    ],
  },
  {
    path: "/chat/:appId",
    lazy: async () => ({
      Component: (await import("@/pages/chat/index")).default,
    }),
  },
  {
    path: "/chat/:appId/:id",
    lazy: async () => ({
      Component: (await import("@/pages/chat/index")).default,
    }),
  },
  {
    path: "*",
    lazy: async () => ({
      Component: (await import("@/pages/404")).default,
    }),
  },
]);

export const AppRouter = () => {
  return <RouterProvider router={Routes} />;
};
