/**
 * 校验中国大陆手机号
 * @param phoneNumber - 需要校验的手机号
 * @returns 是否为有效的手机号
 */
export function validatePhoneNumber(phoneNumber: string): boolean {
    // 全球手机号正则表达式
    const phoneRegex = /^(?:\+?\d{1,3})?[0-9]{10,}$/;
    return phoneRegex.test(phoneNumber);
}



export function getMarkDownHeadings(markdown: string): Heading[] {
    const headingRegex = /^(#+\s)([^#][^=].*?(?=\n[^#]|\n\s*#{1,6}\s|$))/gs;
    const linkRegex = /\[([^\]]+)\]\([^\)]+\)/g;
    let match;
    const headings: Heading[] = [];

    while ((match = headingRegex.exec(markdown))) {
        let title = match[2].replace(linkRegex, (match, p1) => p1);

        headings.push({ level: match[1].length, title: title });
    }

    return headings;
}


export function image(url: string, size?: number) {
    if (!size) return url;
    return `${url}?x-oss-process=image/resize,w_${size}`
}

export function oss(url: string) {
    return `${import.meta.env.VITE_OSS_URL}/${url}`
}

/**
 * 获取网站子域名
 * @returns 
 */
export function getWebsite() {
    const host = window.location.host;
    const website = host.split('.')[0];
    return website;
}


/**
 * 判断是在iframe中
 */
export function isIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
/**
 * 判断是否电脑端
 */
export function isPC() {
    const userAgentInfo = navigator.userAgent;
    const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
        }
    }
    return flag;
}

/**
 * 节流函数
 * @param func 
 * @param wait 
 * @returns 
 */
export function throttle(func: Function, wait: number) {
    let previous = 0;
    return function (this: any) {
        let now = Date.now();
        if (now - previous > wait) {
            func.apply(this, arguments);
            previous = now;
        }
    };
}
/**
 * 防抖函数
 * @param func 
 * @param wait 
 * @returns 
 */
export function debounce(func: Function, wait: number) {
    let timeout: ReturnType<typeof setTimeout>;
    return function (this: any) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, arguments);
        }, wait);
    };
}


