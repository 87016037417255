import { Alert } from "@material-tailwind/react";
import { createContext, useContext, useEffect, useState } from "react";
import { ConfrimModal } from "./Confrim";
import store from "@/utils/store";
import { useHttp } from "@/hooks/useFetch";
import axios from "axios";
import { LoginDialog } from "../common/Login";
import { RegisterDialog } from "../common/Register";
import * as utils from "@/utils";

type ConfrimMessage = {
  title: React.ReactNode;
  message: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  footer?: boolean;
  showCancel?: boolean;
};

type LoginCallback = {
  onSuccess?: () => void;
};

type AlertMessage = {
  message: string;
  color: "blue" | "red" | "green" | "amber" | undefined;
};

export type GlobalContextType = {
  userInfo?: UserInfo;
  setUserInfo?: (userInfo: UserInfo) => void;
  loginModal: boolean;
  setLoginModal: (loginModal: boolean) => void;
  registerModal: boolean;
  setRegisterModal: (loginModal: boolean) => void;
  token: string;
  setToken: (token: string) => void;
  alert: (content: AlertMessage) => void;
  confrim: (message: ConfrimMessage) => void;
  confrimInfo?: ConfrimMessage;
  confirmModal: boolean;
  setConfirmModal: (confirmModal: boolean) => void;
  login: (phone: string, password: string) => void;
  logout: () => void;
  registerCallback?: LoginCallback;
  handleRegister: (callback: LoginCallback) => void;
  utils: typeof utils;
  appInfo: AppInfo | undefined;
  showChat: boolean;
  setShowChat: (showChat: boolean) => void;
};

export const GlobalContext = createContext<GlobalContextType>({
  token: "",
  setToken: () => {},
  loginModal: false,
  setLoginModal: () => {},
  registerModal: false,
  setRegisterModal: () => {},
  alert: () => {},
  confrim: () => {},
  confirmModal: false,
  setConfirmModal: () => {},
  login: () => {},
  logout: () => {},
  registerCallback: {},
  handleRegister: () => {},
  utils,
  appInfo: {} as AppInfo,
  setShowChat: () => {},
  showChat: false,
});

export const GlobalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { Http } = useHttp();

  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [registerModal, setRegisterModal] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");

  const [alertInfo, setAlertInfo] = useState<AlertMessage>();
  const [confrimInfo, setConfrimInfo] = useState<ConfrimMessage>();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const [registerCallback, setRegisterCallback] = useState<LoginCallback>();
  const [appInfo, setAppInfo] = useState<AppInfo>();
  const [showChat, setShowChat] = useState<boolean>(false);

  const checkVersion = async () => {
    if (document.visibilityState != "visible") {
      return setTimeout(() => {
        checkVersion();
      }, 60 * 1000);
    }
    const version = store.getItem("version");
    const { data } = await axios.get("/version.json?t=" + Date.now());

    // if (true) {
    if (version !== data.version) {
      confrim({
        title: <div className="text-center">版本更新</div>,
        message: (
          <div className="text-center ">
            <p className="text-gray-800 text-md font-medium ">检测到新版本，是否更新？</p>
            <p className="text-sm font-medium mt-3 text-gray-800">{data.info}</p>
          </div>
        ),
        onConfirm: () => {
          store.setItem("version", data.version);
          window.location.reload();
        },
      });
    } else {
      setTimeout(() => {
        checkVersion();
      }, 60 * 1000);
    }
  };

  async function handleRegister(callbackOption: LoginCallback) {
    setRegisterCallback(callbackOption);
    setRegisterModal(true);
  }

  const initVersion = async () => {
    const { data } = await axios.get("/version.json?t=" + Date.now());
    store.setItem("version", data.version);
    setTimeout(() => {
      checkVersion();
    }, 60 * 1000);
  };

  const confrim = (message: ConfrimMessage) => {
    setConfrimInfo(message);
    setConfirmModal(true);
  };

  const alert = (content: AlertMessage) => {
    console.log(content);
    setAlertInfo(content);
    setTimeout(() => {
      setAlertInfo(undefined);
    }, 3000);
  };

  const login = async (phone: string, password: string) => {
    try {
      const data: any = await Http.post("/app/user/login/password", {
        phone,
        password,
      });
      store.setItem("token", data.token);

      const userInfo: UserInfo = await Http.get("/app/user/info/person");
      userInfo.avatarUrl = userInfo.avatarUrl || import.meta.env.VITE_AVATAR;
      setUserInfo(userInfo);
      store.setItem("userInfo", userInfo);

      registerCallback?.onSuccess && registerCallback.onSuccess();
      return userInfo;
    } catch (error: any) {
      alert({
        color: "red",
        message: error.message,
      });
      return Promise.reject(error);
    }
  };

  const logout = async () => {
    // Todo: 调用接口登出
    store.removeItem("token");
    store.removeItem("userInfo");
    setShowChat(false);
    setUserInfo(undefined);
  };

  async function initUserInfo() {
    setUserInfo(store.getItem("userInfo") as UserInfo);
  }

  async function getAppInfo() {
    const data: AppInfo = await Http.get("/app/cms/manage/app");
    setAppInfo(data);
  }

  useEffect(() => {
    initVersion();
    initUserInfo();
    getAppInfo();
  }, []);

  useEffect(() => {
    if (appInfo) {
      document.title = appInfo.websiteCn;
    }
  }, [appInfo]);

  return (
    <>
      <GlobalContext.Provider
        value={{
          userInfo,
          setUserInfo,
          loginModal,
          setLoginModal,
          registerModal,
          setRegisterModal,
          token,
          setToken,
          alert,
          confrim,
          confrimInfo,
          confirmModal,
          setConfirmModal,
          login,
          logout,
          registerCallback,
          handleRegister,
          utils,
          appInfo,
          showChat,
          setShowChat,
        }}
      >
        {children}
        {/* 确认弹窗 */}
        <ConfrimModal
          onConfirm={() => {
            setConfirmModal(false);
            confrimInfo?.onConfirm && confrimInfo?.onConfirm();
          }}
          onCancel={() => {
            setConfirmModal(false);
          }}
          showCancel={confrimInfo?.showCancel}
          showFooter={confrimInfo?.footer}
          header={confrimInfo?.title}
          content={confrimInfo?.message}
          open={confirmModal}
        />
        <LoginDialog
          open={loginModal!}
          handleClose={() => setLoginModal!(false)}
        />
        <RegisterDialog />
      </GlobalContext.Provider>

      {/* 全局弹窗 */}
      <Alert
        className="fixed bottom-0 md:bottom-5 right-0 md:right-5 w-full md:w-96 z-[99999] rounded-none sm:rounded-md"
        open={!!alertInfo}
        color={alertInfo?.color}
        onClose={() => setAlertInfo(undefined)}
        animate={{
          mount: { y: 0 },
          unmount: { y: 100 },
        }}
      >
        {alertInfo?.message || ""}
      </Alert>
    </>
  );
};

export const useGlobalData = () => {
  return useContext(GlobalContext);
};
