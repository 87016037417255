export function copyToClipboard({
    text,
    success,
    fail
}: {
    text: string;
    success?: () => void;
    fail?: () => void;
}) {
    if (!navigator.clipboard) {
        // 如果 Clipboard API 不可用，使用备用方案
        fallbackCopyTextToClipboard(text) ? success && success() : fail && fail();
        return;
    }
    navigator.clipboard.writeText(text).then(() => {
        console.log('复制成功');
        success && success();
    }).catch(err => {
        console.error('复制失败: ', err);
        fail && fail();
    });
}

function fallbackCopyTextToClipboard(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";  // 避免滚动条跳动
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        const msg = successful ? '复制成功' : '复制失败';
        console.log(msg);
        document.body.removeChild(textArea);
        // 执行成功后的回调函数
        return true
    } catch (err) {
        console.error('复制失败: ', err);
        document.body.removeChild(textArea);
        return false
    }


}

