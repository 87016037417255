import { Outlet } from "react-router-dom";

import { StickyNavbar } from "./AppNavBar";
import Footer from "./AppFooter";
import { AppChat, BottomButton } from "./AppChat";
import ChatContent from "@/pages/chat/components/Content";
import DataLayout from "@/pages/chat/components/DataLayout";
import { useGlobalData } from "./GlobalContext";
import { useEffect } from "react";

export default function Layout() {
  const { showChat } = useGlobalData();
  
  return (
    <div className="w-full flex">
      <div style={{
        transitionDuration: '300ms'
      }} className={`${showChat ? 'w-[calc(100%-400px)]' : 'w-full'} transition-[width]`}>
        <StickyNavbar />
        <Outlet />
        <Footer />
      </div>
     
      <div style={{
        transitionDuration: '300ms'
      }} className={`${showChat ? 'right-0' : '-right-[400px]'} transition-[right] w-[400px] h-full fixed top-0  bg-white z-[51]`}>
        <BottomButton />
        <AppChat />
      </div>
    </div>
  );
}
