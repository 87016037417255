import React, { forwardRef, useImperativeHandle, useRef } from "react";
import useFetch from "@/hooks/useFetch";

// 定义 `Captcha` 组件，使用 `forwardRef` 处理 `ref`
export const Captcha = forwardRef(
  (props: { onChange?: (value: string) => void }, ref) => {
    const { onChange } = props;
    const Svg = useFetch(
      {
        url: "/app/user/login/captcha",
        method: "get",
        value: {
          captchaId: "",
          data: "",
        },
        params: {
          height: 44,
          width: 150,
          color: "#2c3142",
        },
        onSuccess: (data) => {
          onChange && onChange(data.captchaId);
        },
      },
      true
    );
    const refresh = () => {
      Svg.fetchData();
    };

    // 使用 `useImperativeHandle` 暴露 `refresh` 方法
    useImperativeHandle(ref, () => ({
      refresh,
    }));

    return (
      <div
        onClick={() => refresh()}
        className="w-full h-full"
        dangerouslySetInnerHTML={{
          __html: Svg.data.data,
        }}
      />
    );
  }
);

export default Captcha;
