import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "@material-tailwind/react";
import { useGlobalData } from "./GlobalContext";
import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

export function ConfrimModal({
  open,
  handler,
  header,
  content,
  onConfirm,
  onCancel,
  showFooter = true,
  showCancel = false
}: {
  open: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  handler?: () => void;
  header: React.ReactNode;
  content: React.ReactNode;
  showFooter?: boolean;
  showCancel?: boolean
}) {
  const handleClose = () => {
    handler && handler();
  };

  return (
    <Dialog
      className="bg-transparent shadow-none"
      size="xs"
      open={open}
      handler={() => handleClose()}
    >
      <Card className="mx-auto w-full max-w-[24rem]">
        {showCancel && <XCircleIcon
          className="absolute w-7 right-5 top-5 cursor-pointer"
          onClick={() => onCancel && onCancel()}
        />}
        <div className="text-2xl font-bold pt-7">{header || ""}</div>
        <CardBody className="grid place-items-center gap-4">
          {content || ""}
        </CardBody>
        {showFooter && (
          <CardFooter className="justify-center flex">
            <Button
              variant="text"
              color="blue-gray"
              onClick={() => onCancel && onCancel()}
            >
              取消
            </Button>
            <Button
              className="ml-5"
              variant="gradient"
              onClick={() => onConfirm && onConfirm()}
            >
              好的
            </Button>
          </CardFooter>
        )}
      </Card>
    </Dialog>
  );
}
