import axios from "axios";
import { useGlobalData } from "@/components/Layout/GlobalContext";
import { useEffect, useState } from "react";
import store from "@/utils/store";
import FetchStream from "@/utils/fetchSSE";
import { useParams } from "react-router-dom";
import { list } from "@material-tailwind/react";

const { DEV, VITE_APP_BASE_URL, VITE_OPEN_PROXY } = import.meta.env;

type FetchParams<T> = {
  url: string;
  method?: "get" | "post";
  params?: object;
  autoFetch?: true;
  onSuccess?: (data: T) => void;
  value?: T | undefined;
};

export function useHttp() {
  const { alert, logout } = useGlobalData();

  const route = useParams();
  const toLogin = () => {
    logout();
  };

  const api = axios.create({
    baseURL: DEV && VITE_OPEN_PROXY === "true" ? "/dev/" : VITE_APP_BASE_URL,
    timeout: 1000 * 60,
    responseType: "json",
  });

  api.interceptors.request.use((config) => {
    /**
     * 全局拦截请求发送前提交的参数
     * 以下代码为示例，在请求头里带上 token 信息
     */

    if (config.headers) {
      config.headers["Authorization"] = store.getItem("token");
      config.headers["AppId"] = route.appId || "man";
    }
    // 是否将 POST 请求参数进行字符串化处理
    if (config.method === "post") {
      // config.data = qs.stringify(config.data, {
      //   arrayFormat: 'brackets',
      // })
    }
    return config;
  });

  api.interceptors.response.use(
    (response: any) => {
      /**
       * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
       * 假设返回数据格式为：{ status: 1, error: '', data: '' }
       * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
       * 请求出错时 error 会返回错误信息
       */
      if (response.data.code === 1000) {
        return Promise.resolve(response.data.data);
      } else {
        if (response.data?.message) {
          alert({
            color: "red",
            message: response.data?.message,
          });
          return Promise.reject(response.data);
        }
        return Promise.reject(response.data);
      }
    },
    (error) => {
      const { status } = error.response;
      if (status == 401) {
        toLogin();
        return Promise.reject(error);
      }
      let message = error.message;
      if (message === "Network Error") {
        message = "后端网络故障";
      } else if (message.includes("timeout")) {
        message = "接口请求超时";
      } else if (message.includes("Request failed with status code")) {
        message = `接口${message.substr(message.length - 3)}异常`;
      }
      alert({
        color: "red",
        message,
      });
      return Promise.reject(error);
    }
  );
  return {
    Http: api,
    FetchStream,
  };
}

export default function useFetch<T = any>(
  { value, url, method = "get", params, onSuccess }: FetchParams<T>,
  watch: any = undefined
) {
  const { Http } = useHttp();

  const [data, setData] = useState<T>(value as T);
  const [loading, setLoading] = useState(false);

  const fetchData = async ({
    isPagination,
    param
  }: {
    isPagination?: boolean,
    param?: any
  } = {
    param: {},
    isPagination: false
  }) => {
    setLoading(true);
    const requestParams: any = {
      url: url,
      method: method,
    }
    if (method == 'get') {
      requestParams.params = {
        ...params,
        ...param
      }
    }
    if (method == 'post') {
      requestParams.data = {
        ...params,
        ...param
      }
    }
    const data: T = await Http(requestParams);
    onSuccess && onSuccess(data);
    if (isPagination) {
      
      setData((prev: any) => {
        return {
          ...prev,
          list: [...prev.list, ...(data as Pagination<T>).list]
        }
      });
    } else {
      setData(data);
    }
    
    setLoading(false);
    return data
  };

  if (watch) {
    useEffect(() => {
      console.log(123);
      fetchData();
    }, []);
  }

  return {
    data,
    setData,
    loading,
    fetchData,
  };
}
