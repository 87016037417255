// src/localStorageUtil.ts

class LocalStorageUtil {
  // 设置值
  static setItem(key: string, value: any): void {
    try {
      const stringValue = JSON.stringify(value);
      localStorage.setItem(key, stringValue);
    } catch (error) {
      console.error(`Error setting item ${key} in localStorage`, error);
    }
  }

  // 获取值
  static getItem<T>(key: string): T | null {
    try {
      const stringValue = localStorage.getItem(key);
      if (stringValue === null) {
        return null;
      }
      return JSON.parse(stringValue) as T;
    } catch (error) {
      console.error(`Error getting item ${key} from localStorage`, error);
      return null;
    }
  }

  // 删除值
  static removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing item ${key} from localStorage`, error);
    }
  }

  // 清空 localStorage
  static clear(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('Error clearing localStorage', error);
    }
  }
}

export default LocalStorageUtil;
