import { GlobalContextProvider } from "./components/Layout/GlobalContext";
import { AppRouter } from "./router";
import { ThemeProvider } from "@material-tailwind/react";

function App() {
  const customTheme = {
    drawer: {
      styles: {
        base: {
          overlay: {
            position: "fixed",
          },
        },
      },
    },
  };
  return (
    <>
      <ThemeProvider value={customTheme}>
        <GlobalContextProvider>
          <AppRouter />
        </GlobalContextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
